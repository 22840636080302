/*------------- #THEME FONT --------------*/

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-MediumItalic.eot');
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
  url('../fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Italic.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('../fonts/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Italic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Bold.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
  url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Medium.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BoldItalic.eot');
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('../fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-ThinItalic.eot');
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
  url('../fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.eot');
  src: local('Roboto Black'), local('Roboto-Black'),
  url('../fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Black.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Light.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
  url('../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Light.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-LightItalic.eot');
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('../fonts/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-LightItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BlackItalic.eot');
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
  url('../fonts/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Thin.eot');
  src: local('Roboto Thin'), local('Roboto-Thin'),
  url('../fonts/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Thin.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
